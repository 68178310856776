.hidden-row {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.danh-muc-container {
    padding: 12px 0;
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
}

.icon-hover {
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    opacity: 0.8;
    transform: scale(1.2);

    &:hover {
        opacity: 1;
        background-color: rgb(238, 238, 238);
        padding: 5px;
    }
}

.ac-is-invalid {

    ~.invalid-feedback {
        display: block;
    }

    >div {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }

    ~.invalid-feedback {
        display: block;
    }

    >div {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
}

.z-index-1 {
    z-index: 1;
}

.z-index-5 {
    z-index: 5;
}

.z-index-10 {
    z-index: 10;
}

.input-search {
    outline: none;
    width: 300px;
    padding: 0px 27px 2px 2px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.input-search-icon {
    border: none;
    background-color: white;
    position: absolute;
    top: 3px;
    right: 18px;

    i {
    font-size: 16px;
    }
}