@import "../styles/variable";

.profile-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout {
  display: grid;
  //  grid-template-columns: 20% 80%;
  width: 100%;
  gap: 20px;
}

.layout-half {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 20px;
}

.relative {
  position: relative;
}

.fixed-button {
  position: fixed;
  right: 0;
  z-index: 10;
  width: fit-content;
}

.custom-table {
  border: 1px solid #F4F4F4;
}

.custom-table thead th,
.custom-table tbody td {
  border: 1px solid #F4F4F4;
}

.header-action {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 10px;
  width: 100%;
}

#custom-switch {
  cursor: pointer;
}