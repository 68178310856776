.contract-info {
  background: #fff !important;
  padding: 40px 24px 16px 24px;
}
.contract-annex{
  background: #fff !important;
}
.form-select:disabled{
  background-color: white;
}
.button-add{
  background-color: rgb(178, 233, 232);
  margin:0px 4px 0px 4px
}

.btn-order {
  border: 1px solid #ddd;
  font-size: 12px;
  padding: 2px 0px;
  border-radius: 5px;
  min-width: 80px;
  width: 80px;
  text-align: center;
  margin: auto;

  span{
    position: relative;
    top: -1px;
  }
  
  i{
    font-size: 10px;
  }
  &:hover{
    color: #0052ea;
    i {
      color: #0052ea;
    }
  }
}

.order_logo {
  left: 0px !important;
  top: 12px !important;
  width: 65px !important;
}