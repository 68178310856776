.status {
    padding: 0px 6px;
    font-size: 12px;

    &-1 {
        color: #ed4c78;
        background-color: rgba(237, 76, 120, .1);
    }

    &-2 {
        color: #00ff0d;
        background-color: rgba(0, 255, 21, 0.41);
    }

    &-3 {
        color: #00c9db;
        background-color: rgba(0, 201, 219, .1);
    }

    &-4 {
        color: red;
        background-color: rgba(255, 5, 72, 0.1);
    }

    &-5 {
        background-color: rgba(0, 201, 167, 0.41);
        color: #00c9db;
    }

    &-6 {
        color: red;
        background-color: rgba(255, 5, 72, 0.1);
    }

}