.delete {
  i {
    color: red;
    font-size: 15px;
    cursor: pointer;
  }
}

.edit {
  i {
    color: #009ef7;
    font-size: 15px;
    cursor: pointer;
    margin-right: 15px;
  }
}

.visibility {
  i {
    color: green;
    font-size: 17px;
    cursor: pointer;
  }
}

.print{
  i {
    font-size: 17px;
    cursor: pointer;
  }
}

.approve {
  span {
    padding: 5px 10px;
    color: white;
    background-color: green;
    border-radius: 8px;
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.85;
  }
}