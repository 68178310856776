.button-primary {
  color: white;
  background-color: #519DD9 !important;
  &:hover {
    background: #2B6DBA !important;
  }
  &:focus {
    background: #0D47A1;
    box-shadow: 0px 0px 0px 4px #D3EAF3;
  }
  &:disabled {
    background: #F4F4F4;
    border-radius: 8px;
    color: #CFCFCF;
  }
}

.button-low-primary {
  border: 1px solid #519DD9 !important;
  color: #519DD9;
  &:hover {
    border: 1px solid #2B6DBA !important;
    color: #2B6DBA !important;
    background-color: white;
  }
  &:focus {
    border: 1px solid #0D47A1 !important;
    color: #0D47A1 !important;
    box-shadow: 0px 0px 0px 4px #D3EAF3 !important;
  }
  &:disabled {
    border: 1px solid #CFCFCF;
    color: #CFCFCF;
  }
}

.button-secondary {
border: 1px solid #CFCFCF !important;
background-color: white;
}
