.default {
    background-color: #00c9a7;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 12px;
}

.bullet-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1);
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 12px;

    span {
        position: relative;
        top: -2px;
    }

    i {
        font-size: 7px;
        color: #00c9a7;
    }
}

.bullet-pending {
    color: #00c9db;
    background-color: rgba(0, 201, 219, .1);
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 12px;

    span {
        position: relative;
        top: -2px;
    }

    i {
        font-size: 7px;
        color: #00c9db;
    }
}

.bullet-cancel {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1);
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 12px;

    span {
        position: relative;
        top: -2px;
    }

    i {
        font-size: 7px;
        color: #ed4c78;
    }
}

.bullet-name {
    background-color: #71869d;
    color: white;
    border-radius: 4px;
    padding: 0px 5px;
    font-size: 12px;
}